export const sendMessage = async (message) => {
    const options = {
        method: 'GET',
        // method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        // body: JSON.stringify({ message: message })
    };
    const response = await fetch('data/data.json', options);
    const data = await response.json();
    const date = new Date();

    if (data.success) {
        return {
                origin: 'from',
                msg: data.response.msg,
                date: new Date()
            };
    } else {
        return null;
    }
}