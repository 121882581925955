// Libraries
import { useRef, useState, useEffect } from 'react';

// Services
import { sendMessage } from '../services/Services';

// Styles
import './ChatWindow.global.scss'

const ChatWindow = props => {
    const {
        setCurrent,
        setMessages,
        messages
    } = props;
    const [msgForm, setMsgForm] = useState('');
    const [res, setRes] = useState(null)
    const scrollRef = useRef(null);

    const closeWindow = e => {
        e.preventDefault();
        setCurrent(false);
    }

    const keypressTextarea = e => {
        if (
            e?.charCode == 13 ||
            e?.keyCode == 13 ||
            e?.key === 'Enter'
        ) {
            e.preventDefault();
            handleForm(e);
        }
    }

    const validateText = e => {
        return e.target.value = e.target.value
            .replace(/[^a-zA-Z0-9.,/ @()\s]/gi, '')
            .substring(0,350)
    }

    const handleForm = async e => {
        e.preventDefault();

        if (!!msgForm) {
            setMessages([
                ...messages,
                {
                    origin: 'to',
                    msg: msgForm,
                    date: new Date()
                }
            ]);

            setRes(await sendMessage(msgForm));
            setMsgForm('');
        } else {
            return false;
        }
    }

    useEffect(() => {
        if (messages.length == 0 ) {
            setMessages([
                {
                    origin: 'from',
                    msg: '¡Bienvenido!',
                    date: new Date()
                }
            ]);
        }

        scrollRef.current.scrollIntoView();
    }, [])

    useEffect(() => {
        scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }, [messages]);

    useEffect(() => {
        if (!!res) {
            setTimeout(() => {
                    setMessages([
                        ...messages,
                        res
                    ])
            }, 500)
        }
    }, [res])

    return (
        <div className="chatbot-window">
            <div className="chatbot-window-header">
                <button
                    className="chatbot-window-close"
                    type="button"
                    onClick={closeWindow}
                >&times;</button>
                <h2>Envíanos un mensaje</h2>
            </div>
            <div className="chatbot-window-body">
                <div className="chatbot-conversation">
                    <div className="chatbot-window-content">
                        {
                            messages &&
                            messages.length > 0 &&
                            messages.map(({origin, msg, date}, key) => {
                                return (
                                    <div
                                        className={`chatbot-${origin}`}
                                        key={key}
                                    >
                                        <div className="text">{msg}</div>
                                        <span className="tag-time">
                                            {date.getHours() + ' : ' + date.getMinutes()}
                                        </span>
                                    </div>
                                )
                            }) 
                        }
                        <div ref={scrollRef}></div>
                    </div>
                </div>
            </div>
            <div className="chatbot-window-footer">
                <form onSubmit={handleForm}>
                    <textarea
                        className="chatbot-textarea"
                        placeholder="Escribe tu mensaje..."
                        value={msgForm}
                        onInput={validateText}
                        onKeyPress={keypressTextarea}
                        onChange={e => setMsgForm(e.target.value)}
                    ></textarea>
                    <button
                        className="chatbot-btn-send"
                        type="submit"
                    >&#x27A4;</button>
                </form>
            </div>
        </div>
    )
};

export default ChatWindow;