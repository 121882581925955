// Libraries
import { useState } from 'react';

// Components
import ChatWindow from './components/ChatWindow';

// Styles
import './Chatbot.global.scss';

const Chatbot = () => {
    const [current, setCurrent] = useState(false);
    const [messages, setMessages] = useState([]);

    const handleCurrent = e => {
        e.preventDefault();
        setCurrent(!current)
    }

    return (
        <div className="chatbot-container">
            {
                !current ? (
                    <div className="chatbot-button-wrap">
                        <a
                            className="chatbot-button"
                            href="#"
                            onClick={handleCurrent}
                        >
                            <span className="chatbot-button-avatar">A</span>
                        </a>

                        <div className="chatbot-button-msg">
                            <h4>¡Bienvenido!</h4>
                            <p>¡Hola, yo te puedo ayudar!</p>
                        </div>
                    </div>
                ) : (
                    <ChatWindow
                        setCurrent={setCurrent}
                        setMessages={setMessages}
                        messages={messages}
                    />
                )
            }
            

        </div>
    );
}
    
export default Chatbot;