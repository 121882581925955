import React from 'react';
import ReactDOM from 'react-dom';
import Chatbot from './Chatbot';
import reportWebVitals from './reportWebVitals';

// Styles
import './index.global.scss';

ReactDOM.render(
    <React.StrictMode>
        <Chatbot />
    </React.StrictMode>,
    document.getElementById('root')
);

reportWebVitals();
